import {
  ComputedFieldProps,
  FormRow,
  FormSection,
  RadioGroupField,
} from "design-system";
import { boolean, option, record } from "fp-ts";
import { constNull, identity, pipe } from "fp-ts/function";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { Option } from "fp-ts/Option";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { foldTenant, MonthYear } from "../../../../../globalDomain";
import { useFormatMessage } from "../../../../../intl";
import { useAppContext } from "../../../../../useAppContext";
import { MoneyField } from "../../../../../Common/Price/MoneyField/MoneyField";
import { useTenantCurrency } from "../../../../../Common/useTenantCurrency";
import { YesNoRadioGroupField } from "../../../../../Common/YesNoRadioGroup/YesNoRadioGroupField";
import { EmploymentType } from "../../../api";
import { JobPositionDropdown } from "../../../commons/JobPositionsDropdown";
import { MonthYearField } from "../../../commons/MonthYearField";
import {
  isOptionTrue,
  PaymentMethod,
  PaymentMethodCZ,
  PaymentMethodSK,
  useFormatPaymentMethod,
} from "../../../domain";
import { EmploymentTypeDropdown } from "./EmploymentTypeDropdown";
import { STAKELIMIT } from "../CompanySection";

type Props = {
  employedInCompanyFieldProps: ComputedFieldProps<Option<boolean>>;
  monthlyIncomeFieldProps: ComputedFieldProps<Option<number>>;
  startingDateFieldProps: ComputedFieldProps<Option<MonthYear>>;
  inProbationFieldProps: ComputedFieldProps<Option<boolean>>;
  contractIndefinitePeriodFieldProps: ComputedFieldProps<Option<boolean>>;
  workBasedOnAgreementOnWorkingActivityFieldProps: ComputedFieldProps<
    Option<boolean>
  >;
  paymentMethodFieldProps: ComputedFieldProps<Option<PaymentMethod>>;
  jobPositionFieldProps: ComputedFieldProps<Option<NonEmptyString>>;
  employmentTypeFieldProps: ComputedFieldProps<Option<EmploymentType>>;
  isEquityPositive: Option<boolean>;
  stakeInCompany: ComputedFieldProps<Option<number>>;
};

const MIN_MONTHLY_INCOME_VALUE = 0;
const MAX_MONTHLY_INCOME_VALUE = 999999999;

export function ContractSection(props: Props) {
  const formatMessage = useFormatMessage();
  const {
    apiParameters: { tenant },
  } = useAppContext();

  const currency = useTenantCurrency();
  const currentYear = new Date().getFullYear();
  const formatPaymentMethod = useFormatPaymentMethod();

  const paymentMethods = pipe(
    foldTenant(
      tenant,
      () => record.keys(PaymentMethodSK.keys) as NonEmptyArray<PaymentMethod>,
      () => record.keys(PaymentMethodCZ.keys) as NonEmptyArray<PaymentMethod>
    )
  );

  const inProbationField = (
    <FormRow type="full">
      <YesNoRadioGroupField
        {...props.inProbationFieldProps}
        label={formatMessage(
          "StandardLoan.CompanyOwner.ContractSection.isInNoticePerodLabel"
        )}
      />
    </FormRow>
  );

  function isStakeInCompanyOverStakeLimit(): boolean {
    return pipe(
      props.stakeInCompany.value,
      option.exists(value => value >= STAKELIMIT)
    );
  }

  return pipe(
    isOptionTrue(props.isEquityPositive) ||
      isOptionTrue(props.employedInCompanyFieldProps.value) ||
      isStakeInCompanyOverStakeLimit(),
    boolean.fold(constNull, () => (
      <FormSection
        heading={{
          title: formatMessage(
            "StandardLoan.CompanyOwner.ContractSection.title"
          ),
        }}
      >
        {(isOptionTrue(props.isEquityPositive) ||
          isStakeInCompanyOverStakeLimit()) && (
          <FormRow type="full">
            <YesNoRadioGroupField
              {...props.employedInCompanyFieldProps}
              label={formatMessage(
                "StandardLoan.CompanyOwner.ContractSection.isEmployOfTheCompanyLabel"
              )}
            />
          </FormRow>
        )}
        {(isOptionTrue(props.isEquityPositive) ||
          isStakeInCompanyOverStakeLimit()) &&
          isOptionTrue(props.employedInCompanyFieldProps.value) && [
            <FormRow type="full">
              <MoneyField
                {...props.monthlyIncomeFieldProps}
                currency={currency}
                label={formatMessage(
                  "StandardLoan.CompanyOwner.ContractSection.netMonthlyIncomeLabel"
                )}
                description={formatMessage(
                  "StandardLoan.CompanyOwner.ContractSection.netMonthlyIncomeTooltip"
                )}
                placeholder={formatMessage(
                  "StandardLoan.CompanyOwner.ContractSection.netMonthlyIncomePlaceholder"
                )}
                min={MIN_MONTHLY_INCOME_VALUE}
                max={MAX_MONTHLY_INCOME_VALUE}
              />
            </FormRow>,
            <FormRow type="full">
              <MonthYearField
                {...props.startingDateFieldProps}
                label={formatMessage(
                  "StandardLoan.CompanyOwner.ContractSection.startingDateAtPresentEmployerLabel"
                )}
                clearable={false}
                minYear={currentYear - 100}
                maxYear={currentYear}
                searchable
                monthSelectionYearLimit={3}
                checkMonthLimit
                defaultMonth={11}
              />
            </FormRow>,
            foldTenant(
              tenant,
              () => [
                <JobPositionDropdown
                  fieldProps={props.jobPositionFieldProps}
                />,
                <EmploymentTypeDropdown
                  fieldProps={props.employmentTypeFieldProps}
                />,
                inProbationField,
              ],
              () => [
                inProbationField,
                <FormRow type="full">
                  <YesNoRadioGroupField
                    {...props.contractIndefinitePeriodFieldProps}
                    label={formatMessage(
                      "StandardLoan.CompanyOwner.ContractSection.contractIndefinitePeriod"
                    )}
                  />
                </FormRow>,
                <FormRow type="full">
                  <YesNoRadioGroupField
                    {...props.workBasedOnAgreementOnWorkingActivityFieldProps}
                    label={formatMessage(
                      "StandardLoan.CompanyOwner.ContractSection.isBasedOnWorkingActivityLabel"
                    )}
                  />
                </FormRow>,
              ]
            ),
            <FormRow type="full">
              <RadioGroupField
                {...props.paymentMethodFieldProps}
                variant="vertical"
                renderOptionChildren={() => option.some(<></>)}
                optionKey={identity}
                isOptionDisabled={() => false}
                renderOption={formatPaymentMethod}
                options={paymentMethods}
                label={formatMessage(
                  "StandardLoan.CompanyOwner.ContractSection.paymentMethodLabel"
                )}
              />
            </FormRow>,
          ]}
      </FormSection>
    ))
  );
}
