import {
  ComputedFieldProps,
  LocalizedString,
  NumberField,
} from "design-system";
import { option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { useFormatIncomeCurrency, useFormatMessage } from "../../../intl";

type MonthlyIncomeProps = {
  fieldProps: ComputedFieldProps<Option<number>>;
  currency: string;
  description?: LocalizedString;
  min?: number;
  max?: number;
};

export function MonthlyIncome(props: MonthlyIncomeProps) {
  const formatMessage = useFormatMessage();
  const formatCurrency = useFormatIncomeCurrency();

  return (
    <NumberField
      {...props.fieldProps}
      rightContent={option.some(formatCurrency(props.currency))}
      label={formatMessage("StandardLoan.EmployedCard.monthlyIncome")}
      placeholder={formatMessage(
        "StandardLoan.EmployedCard.monthlyIncomePlaceholder"
      )}
      description={props.description}
      min={props.min}
      max={props.max}
      locale="sk"
    />
  );
}
