import { useForm, validators } from "design-system";
import { boolean, option, taskEither } from "fp-ts";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { useEffect, useRef } from "react";
import { foldTenant } from "../../../../globalDomain";
import { useAppContext } from "../../../../useAppContext";
import { useValidators } from "../../../../Common/useValidators";
import {
  IncomeCardProps,
  useControlledSubmit,
} from "../../../../StandardLoan/IncomeForm/commons/useControlledSubmit";
import {
  constOptionNoneValidator,
  getEmptyAllowanceInfo,
  getEmptyCompanyInfo,
  getEmptyContractInfo,
  getEmptyIncomeInfoSpecial,
} from "../../../../StandardLoan/IncomeForm/commons/Utils";
import {
  IncomeData,
  PaymentMethod,
  useFormatPaymentMethod,
} from "../../../../StandardLoan/IncomeForm/domain";
import { PensionerFormFields, PensionType } from "./domain";
import { PensionerForm } from "./PensionerForm";

export function PensionerCard(props: IncomeCardProps) {
  const { incomeData, options } = props;

  const {
    apiParameters: { tenant },
  } = useAppContext();

  const { definedNoExtract, nonNegativeNumber } = useValidators();
  const validatedValues = useRef<Option<IncomeData>>(option.none);

  const isRetirement = (value: Option<PensionType>) =>
    pipe(
      value,
      option.exists(value => value === "Retirement")
    );

  const formatPaymentMethod = useFormatPaymentMethod();

  const { fieldProps, handleSubmit, resetForm } = useForm(
    {
      initialValues: {
        pensionType: pipe(
          incomeData.incomeInfo,
          option.chain(value => value.pensionerIncomeDetails),
          option.chain(value => value.pensionType)
        ),
        monthlyPension: pipe(
          incomeData.incomeInfo,
          option.chain(value => value.pensionerIncomeDetails),
          option.chain(value => value.monthlyPension)
        ),
        paymentMethod: pipe(
          incomeData.contractInfo,
          option.chain(data => data.paymentMethod)
        ),
        monthlyRent: pipe(
          incomeData.incomeInfo,
          option.chain(value => value.pensionerIncomeDetails),
          option.chain(value => value.monthlyRent)
        ),
      } as PensionerFormFields,
      fieldValidators: values => ({
        pensionType: definedNoExtract<PensionType>(),
        monthlyPension: foldTenant(
          tenant,
          () =>
            validators.inSequence(
              definedNoExtract<number>(),
              validators.validateIfDefined(nonNegativeNumber)
            ),
          () =>
            pipe(
              isRetirement(values.pensionType),
              boolean.fold(
                () =>
                  validators.inSequence(
                    definedNoExtract<number>(),
                    validators.validateIfDefined(nonNegativeNumber)
                  ),
                constOptionNoneValidator
              )
            )
        ),
        monthlyRent: pipe(
          isRetirement(values.pensionType),
          boolean.fold(constOptionNoneValidator, () =>
            validators.inSequence(
              definedNoExtract<number>(),
              validators.validateIfDefined(nonNegativeNumber)
            )
          )
        ),
        paymentMethod: foldTenant(
          tenant,
          () => definedNoExtract<PaymentMethod>(),
          constOptionNoneValidator
        ),
      }),
    },
    {
      onSubmit: data =>
        taskEither.fromIO(() => {
          validatedValues.current = option.some({
            ...incomeData,
            incomeInfo: option.some({
              ...getEmptyIncomeInfoSpecial("Pensioner"),
              pensionerIncomeDetails: option.some({
                pensionType: data.pensionType,
                monthlyPension: data.monthlyPension,
                monthlyRent: data.monthlyRent,
              }),
            }),
            contractInfo: option.some({
              ...getEmptyContractInfo(),
              paymentMethod: data.paymentMethod,
              paymentMethodLabel: pipe(
                data.paymentMethod,
                option.map(paymentMethod => formatPaymentMethod(paymentMethod))
              ),
            }),
            companyInfo: option.some({
              ...getEmptyCompanyInfo(),
            }),
            allowanceInfo: option.some({
              ...getEmptyAllowanceInfo(),
            }),
          });
        }),
    }
  );
  useEffect(() => {
    resetForm();
  }, [props.employmentType]);
  useControlledSubmit(props, handleSubmit, validatedValues);

  return (
    <PensionerForm
      fieldProps={fieldProps}
      disabled={!options.isEditing}
      rework={props.rework}
      reworkAll={props.reworkAll}
    />
  );
}
