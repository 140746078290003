import {
  ComputedFieldProps,
  DropdownOption,
  fieldIssues,
  FormRow,
  FormSection,
  Issues,
  Stack,
  useForm,
  validators,
} from "design-system";
import { array, boolean, option, record, taskEither } from "fp-ts";
import {
  constFalse,
  constNull,
  constUndefined,
  constVoid,
  pipe,
} from "fp-ts/function";
import { IO } from "fp-ts/IO";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { Option } from "fp-ts/Option";
import { Reader } from "fp-ts/Reader";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useFormatMessage } from "../../intl";
import { DropdownField } from "../../Common/DropdownField/DropdownField";
import {
  dropdownOptionToValue,
  selectedDropdownOption,
} from "../../Common/selectDropdownOption";
import { useValidators } from "../../Common/useValidators";
import { ReworkIncomeAndPersonalData, ReworkIncomeOutput } from "../Rework/api";
import { IncomeCardProps } from "./commons/useControlledSubmit";
import {
  FlattenedIncomeSourceType,
  foldIncomeBySource,
  IncomeData,
  IncomeOutput,
  IncomeSourceType,
  SpecialIncomeSourceType,
  useFormatIncomeSourceType,
} from "./domain";
import { AlimonyCard } from "./Forms/AlimonyCard";
import { CompanyOwnerCard } from "./Forms/CompanyOwnerForm/CompanyOwnerCard";
import { EmployedCard } from "./Forms/EmployedForm";
import { FreelancerCard } from "./Forms/FreelancerCard";
import { MaternityLeaveCard } from "./Forms/MaternityLeaveForm";
import { PensionerCard } from "./Forms/PensionerForm/PensionerCard";
import { TradesManCard } from "./Forms/TradesManForm/TradesManCard";
import { isReworked } from "../Rework/useReworkComparator";
import { foldTenant } from "../../globalDomain";
import { useAppContext } from "../../useAppContext";

type ValidatedFormData = Exclude<
  IncomeData,
  "sourceOfIncome" | "specialSourceOfIncome"
> &
  (
    | {
        sourceOfIncome: Exclude<IncomeSourceType, "SpecialType">;
      }
    | {
        sourceOfIncome: "SpecialType";
        specialSourceOfIncome: SpecialIncomeSourceType;
      }
  );

type Props = Omit<IncomeCardProps, "rework" | "employmentType"> & {
  onDataReady: Reader<IncomeOutput, unknown>;
  rework: Option<ReworkIncomeAndPersonalData>;
  reworkAll: boolean;
  onChangeIncomeSourceType: (value: Option<IncomeSourceType>) => void;
  isCancelling: boolean;
  onCancel: Dispatch<SetStateAction<boolean>>;
};

export function IncomeForm(props: Props) {
  const { nonNegativeNumber, defined } = useValidators();
  const validatedValues = useRef<Option<ValidatedFormData>>(option.none);
  const formatIncomeSourceType = useFormatIncomeSourceType();

  const [incomeData, resetIncomeData] = useState(props.incomeData);

  const {
    apiParameters: { tenant },
  } = useAppContext();

  const { fieldProps, handleSubmit, values, setValues, setTouched } = useForm(
    {
      initialValues: {
        ...incomeData,
        sourceOfIncome: pipe(
          incomeData.incomeInfo,
          option.fold(
            () => incomeData.incomeOptions.sourceOfIncomeDefault,
            ({ incomeSource }) => option.some(incomeSource)
          )
        ),
        specialSourceOfIncome: pipe(
          incomeData.incomeInfo,
          option.fold(
            () => incomeData.incomeOptions.specialTypeOfIncomeDefault,
            foldIncomeBySource({
              whenStandard: () =>
                option.none as Option<SpecialIncomeSourceType>,
              whenSpecial: ({ specialTypeOfIncome }) =>
                option.some(specialTypeOfIncome),
            })
          )
        ),
      },
      fieldValidators: values => ({
        income: validators.inSequence(defined(), nonNegativeNumber),
        sourceOfIncome: defined<IncomeSourceType>(),
        specialSourceOfIncome: pipe(
          values.sourceOfIncome,
          option.exists(sourceOfIncome => sourceOfIncome === "SpecialType"),
          boolean.fold(constUndefined, () => defined<SpecialIncomeSourceType>())
        ),
      }),
    },
    {
      onSubmit: values =>
        taskEither.fromIO(() => {
          validatedValues.current = option.some(values as ValidatedFormData);
        }),
    }
  );

  const onDataReady = (values: IncomeData) =>
    setTimeout(
      () =>
        pipe(
          handleSubmit,
          taskEither.chain(() =>
            taskEither.fromIO(() =>
              pipe(
                {
                  ...values,
                  incomeOptions: undefined,
                  incomeSourceList: undefined,
                  specialIncomeSourceList: undefined,
                  incomeInfo: pipe(
                    values.incomeInfo,
                    option.map(incomeInfo => ({
                      ...incomeInfo,
                      incomeSourceLabel: option.some(
                        formatIncomeSourceType(incomeInfo.incomeSource)
                      ),
                    }))
                  ),
                },
                props.onDataReady
              )
            )
          ),
          taskEither.orElse(() => taskEither.fromIO(props.onFailure))
        )(),
      50
    );

  const reworkData = pipe(
    props.rework,
    option.chain(rework => rework.income)
  );

  const selectedCard = pipe(
    fieldProps("sourceOfIncome").value,
    option.exists(value => value === "SpecialType"),
    boolean.fold(
      () =>
        fieldProps("sourceOfIncome").value as Option<FlattenedIncomeSourceType>,
      () => fieldProps("specialSourceOfIncome").value
    ),
    option.fold(
      constNull,
      foldIncomeCard({
        Employed: () => (
          <EmployedCard
            canSubmit={props.canSubmit}
            onDataReady={onDataReady}
            onFailure={props.onFailure}
            incomeData={incomeData}
            options={props.options}
            abroad={false}
            rework={reworkData}
            employmentType={fieldProps("sourceOfIncome").value}
            isMainIncome={props.isMainIncome}
            reworkAll={props.reworkAll}
          />
        ),
        CompanyOwner: () => (
          <CompanyOwnerCard
            canSubmit={props.canSubmit}
            onDataReady={onDataReady}
            onFailure={props.onFailure}
            incomeData={incomeData}
            options={props.options}
            rework={reworkData}
            employmentType={fieldProps("sourceOfIncome").value}
            isMainIncome={props.isMainIncome}
            reworkAll={props.reworkAll}
          />
        ),
        Freelancer: () => (
          <FreelancerCard
            canSubmit={props.canSubmit}
            onDataReady={onDataReady}
            onFailure={props.onFailure}
            incomeData={incomeData}
            options={props.options}
            rework={reworkData}
            employmentType={fieldProps("sourceOfIncome").value}
            isMainIncome={props.isMainIncome}
            reworkAll={props.reworkAll}
          />
        ),
        TradesmanCoOperatingPerson: () => (
          <TradesManCard
            canSubmit={props.canSubmit}
            onDataReady={onDataReady}
            onFailure={props.onFailure}
            incomeData={incomeData}
            options={props.options}
            rework={reworkData}
            employmentType={fieldProps("sourceOfIncome").value}
            isMainIncome={props.isMainIncome}
            reworkAll={props.reworkAll}
          />
        ),
        MaternityLeave: () => (
          <MaternityLeaveCard
            canSubmit={props.canSubmit}
            onDataReady={onDataReady}
            onFailure={props.onFailure}
            incomeData={incomeData}
            options={props.options}
            rework={reworkData}
            employmentType={fieldProps("sourceOfIncome").value}
            isMainIncome={props.isMainIncome}
            reworkAll={props.reworkAll}
          />
        ),
        Pensioner: () => (
          <PensionerCard
            canSubmit={props.canSubmit}
            onDataReady={onDataReady}
            onFailure={props.onFailure}
            incomeData={incomeData}
            options={props.options}
            rework={reworkData}
            employmentType={fieldProps("sourceOfIncome").value}
            isMainIncome={props.isMainIncome}
            reworkAll={props.reworkAll}
          />
        ),
        Alimony: () => (
          <AlimonyCard
            canSubmit={props.canSubmit}
            onDataReady={onDataReady}
            onFailure={props.onFailure}
            incomeData={incomeData}
            options={props.options}
            rework={reworkData}
            employmentType={fieldProps("sourceOfIncome").value}
            isMainIncome={props.isMainIncome}
            reworkAll={props.reworkAll}
          />
        ),
        EmployedAbroad: () => (
          <EmployedCard
            canSubmit={props.canSubmit}
            onDataReady={onDataReady}
            onFailure={props.onFailure}
            incomeData={incomeData}
            options={props.options}
            abroad={true}
            rework={reworkData}
            employmentType={fieldProps("sourceOfIncome").value}
            isMainIncome={props.isMainIncome}
            reworkAll={props.reworkAll}
          />
        ),
      })
    )
  );

  useEffect(
    () =>
      pipe(
        props.canSubmit,
        boolean.fold(constVoid, () =>
          pipe(
            !selectedCard,
            boolean.fold(constVoid, () => onDataReady(values))
          )
        )
      ),
    [selectedCard, props.onFailure, props.canSubmit]
  );

  useEffect(() => {
    if (props.isCancelling) {
      handleCancel();
    }
    props.onCancel(false);
  }, [props.isCancelling]);

  const handleReset = (value: Option<IncomeSourceType>): void => {
    resetIncomeData({
      ...incomeData,
      allowanceInfo: option.none,
      companyInfo: option.none,
      contractInfo: option.none,
      incomeInfo: option.none,
      // uniqueId: option.none,
    });
    setValues(incomeData);
    setTouched(pipe(incomeData, record.map(constFalse)));
    props.onChangeIncomeSourceType(value);
  };

  const handleCancel = (): void => {
    resetIncomeData({
      ...props.incomeData,
      allowanceInfo: props.incomeData.allowanceInfo,
      companyInfo: props.incomeData.companyInfo,
      contractInfo: props.incomeData.contractInfo,
      incomeInfo: props.incomeData.incomeInfo,
    });
    setValues({
      ...props.incomeData,
      sourceOfIncome: pipe(
        props.incomeData.incomeInfo,
        option.fold(
          () => incomeData.incomeOptions.sourceOfIncomeDefault,
          ({ incomeSource }) => option.some(incomeSource)
        )
      ),
      specialSourceOfIncome: pipe(
        props.incomeData.incomeInfo,
        option.fold(
          () => incomeData.incomeOptions.specialTypeOfIncomeDefault,
          foldIncomeBySource({
            whenStandard: () => option.none as Option<SpecialIncomeSourceType>,
            whenSpecial: ({ specialTypeOfIncome }) =>
              option.some(specialTypeOfIncome),
          })
        )
      ),
    });
  };
  const keys = foldTenant<Array<SpecialIncomeSourceType>>(
    tenant,
    () => ["MaternityLeave", "Pensioner"] as Array<SpecialIncomeSourceType>,
    () =>
      [
        "Alimony",
        "MaternityLeave",
        "Pensioner",
      ] as Array<SpecialIncomeSourceType>
  );
  return (
    <Stack column grow shrink units={6}>
      <IncomeTypeSelection
        incomeSourceList={incomeData.incomeSourceList}
        specialIncomeSourceList={
          props.isMainIncome
            ? incomeData.specialIncomeSourceList
            : option.some(keys)
        }
        sourceOfIncome={fieldProps("sourceOfIncome")}
        specialSourceOfIncome={fieldProps("specialSourceOfIncome")}
        isEditing={props.options.isEditing}
        isMainIncome={props.isMainIncome}
        rework={pipe(
          props.rework,
          option.chain(rework => rework.income)
        )}
        onReset={handleReset}
      />
      {selectedCard}
    </Stack>
  );
}

export function foldIncomeCard<T>(
  matches: {
    [k in FlattenedIncomeSourceType]: IO<T>;
  }
): Reader<FlattenedIncomeSourceType, T> {
  return sourceOfIncome => matches[sourceOfIncome]();
}

type IncomeTypeSelectionProps = {
  sourceOfIncome: ComputedFieldProps<Option<IncomeSourceType>>;
  specialSourceOfIncome: ComputedFieldProps<Option<SpecialIncomeSourceType>>;
  incomeSourceList: NonEmptyArray<IncomeSourceType>;
  specialIncomeSourceList: Option<SpecialIncomeSourceType[]>;
  isEditing: boolean;
  isMainIncome: boolean;
  rework: Option<ReworkIncomeOutput>;
  onReset: (value: Option<IncomeSourceType>) => void;
};

export function IncomeTypeSelection(props: IncomeTypeSelectionProps) {
  const formatIncomeSourceType = useFormatIncomeSourceType();
  const formatMessage = useFormatMessage();

  const isSourceReworked = pipe(
    props.rework,
    option.chain(rework => rework.incomeInfo),
    option.chain(incomeInfo => incomeInfo.incomeSource),
    option.isSome
  );

  const isSpecialTypeReworked = pipe(
    props.rework,
    option.chain(rework => rework.incomeInfo),
    option.chain(incomeInfo => incomeInfo.specialTypeOfIncome),
    option.isSome
  );

  const incomeSourceReworked = isReworked(
    props.rework,
    "incomeInfo",
    "incomeSource"
  );
  const reworkDependent = incomeSourceReworked;

  const sourceOptions: Array<DropdownOption<IncomeSourceType>> = pipe(
    props.incomeSourceList,
    array.map(incomeSource => ({
      label: formatIncomeSourceType(incomeSource),
      value: incomeSource,
    }))
  );

  const specialSourceOptions: Array<
    DropdownOption<SpecialIncomeSourceType>
  > = pipe(
    props.specialIncomeSourceList,
    option.getOrElse<SpecialIncomeSourceType[]>(() => []),
    array.map(incomeSource => ({
      label: formatIncomeSourceType(incomeSource),
      value: incomeSource,
    }))
  );

  function formatIssues(
    condition: boolean,
    existingIssues: Option<Issues>
  ): Option<Issues> {
    return option.isNone(existingIssues) && condition
      ? option.some(
          fieldIssues.warnings([
            formatMessage("StandardLoan.Rework.fieldChangedMessage"),
          ])
        )
      : existingIssues;
  }

  const handleChange = (dropdownOption: Option<DropdownOption<any>>): void => {
    props.onReset(dropdownOptionToValue(dropdownOption));
    props.sourceOfIncome.onChange(dropdownOptionToValue(dropdownOption));
  };

  const sourceOfIncomeField = (
    <DropdownField
      {...props.sourceOfIncome}
      label={
        props.isMainIncome
          ? formatMessage("StandardLoan.IncomeForm.sourceOfIncome")
          : formatMessage("StandardLoan.IncomeForm.additionalSourceOfIncome")
      }
      placeholder={
        props.isMainIncome
          ? formatMessage("StandardLoan.IncomeForm.sourceOfIncomePlaceholder")
          : formatMessage(
              "StandardLoan.IncomeForm.additionalSourceOfIncomePlaceholder"
            )
      }
      options={sourceOptions}
      clearable={false}
      searchable={false}
      value={selectedDropdownOption(props.sourceOfIncome.value, sourceOptions)}
      onChange={handleChange}
      disabled={
        !props.isEditing || (option.isSome(props.rework) && !isSourceReworked)
      }
      issues={formatIssues(isSourceReworked, props.sourceOfIncome.issues)}
    />
  );

  const specialSourceOfIncomeField = (
    <DropdownField
      {...props.specialSourceOfIncome}
      label={formatMessage("StandardLoan.IncomeForm.specialSourceOfIncome")}
      placeholder={formatMessage(
        "StandardLoan.IncomeForm.specialSourceOfIncomePlaceholder"
      )}
      options={specialSourceOptions}
      clearable={false}
      searchable={false}
      value={selectedDropdownOption(
        props.specialSourceOfIncome.value,
        specialSourceOptions
      )}
      onChange={value =>
        props.specialSourceOfIncome.onChange(dropdownOptionToValue(value))
      }
      disabled={
        !props.isEditing || (option.isSome(props.rework) && !reworkDependent)
      }
      issues={formatIssues(isSpecialTypeReworked, props.sourceOfIncome.issues)}
    />
  );

  return (
    <FormSection
      heading={{
        title: formatMessage("StandardLoan.IncomeSource.title"),
      }}
    >
      <FormRow type="full">{sourceOfIncomeField}</FormRow>
      {pipe(
        props.sourceOfIncome.value,
        option.exists(value => value === "SpecialType")
      ) && <FormRow type="full">{specialSourceOfIncomeField}</FormRow>}
    </FormSection>
  );
}
