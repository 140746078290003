import { FormRow, Stack, useForm, validators } from "design-system";
import { option, taskEither } from "fp-ts";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { useRef, useEffect } from "react";
import { useFormatMessage } from "../../../../intl";
import { MoneyField } from "../../../../Common/Price/MoneyField/MoneyField";
import { useTenantCurrency } from "../../../../Common/useTenantCurrency";
import { useValidators } from "../../../../Common/useValidators";
import {
  isReworked,
  useReworkComparator,
} from "../../../Rework/useReworkComparator";
import { AllowanceType } from "../../api";
import { AllowanceTypeDropdown } from "../../commons/AllowanceTypeDropdown";
import {
  IncomeCardProps,
  useControlledSubmit,
} from "../../commons/useControlledSubmit";
import {
  getEmptyAllowanceInfo,
  getEmptyCompanyInfo,
  getEmptyContractInfo,
  getEmptyIncomeInfoSpecial,
} from "../../commons/Utils";
import {
  IncomeData,
  MaternityOrParentalIncomeDetails,
  MaternityParentalLeaveFormData,
} from "../../domain";
import * as classes from "./MaternityLeaveCard.treat";

export function MaternityLeaveCard(props: IncomeCardProps) {
  const { definedNoExtract, amountMinMax } = useValidators();
  const validatedValues = useRef<Option<IncomeData>>(option.none);
  const formatMessage = useFormatMessage();
  const tenantCurrency = useTenantCurrency();
  const monthlySocialBenefitMax = 999999999;
  const { options, incomeData } = props;

  const { specialFieldsReworkFieldProps } = useReworkComparator(props.rework);

  const { fieldProps, handleSubmit, resetForm } = useForm(
    {
      initialValues: {
        allowanceType: pipe(
          incomeData.incomeInfo,
          option.chain(value => value.maternityOrParentalIncomeDetails),
          option.chain(value => value.allowanceType)
        ),
        monthlySocialBenefit: pipe(
          incomeData.incomeInfo,
          option.chain(value => value.maternityOrParentalIncomeDetails),
          option.chain(value => value.monthlySocialBenefit)
        ),
      } as MaternityParentalLeaveFormData,
      fieldValidators: () => ({
        allowanceType: definedNoExtract<AllowanceType>(),
        monthlySocialBenefit: validators.inSequence(
          definedNoExtract<number>(),
          validators.validateIfDefined(amountMinMax(0, monthlySocialBenefitMax))
        ),
      }),
    },
    {
      onSubmit: values =>
        taskEither.fromIO(() => {
          validatedValues.current = option.some({
            ...incomeData,
            incomeInfo: option.some({
              ...getEmptyIncomeInfoSpecial("MaternityLeave"),
              maternityOrParentalIncomeDetails: option.some({
                allowanceType: values.allowanceType,
                monthlySocialBenefit: values.monthlySocialBenefit,
              }),
            }),
            companyInfo: option.some({
              ...getEmptyCompanyInfo(),
            }),
            contractInfo: option.some({
              ...getEmptyContractInfo(),
            }),
            allowanceInfo: option.some({
              ...getEmptyAllowanceInfo(),
            }),
          });
        }),
    }
  );
  useEffect(() => {
    resetForm();
  }, [props.employmentType]);
  useControlledSubmit(props, handleSubmit, validatedValues);

  const reworkedMaternityLeave = (
    maternityField: keyof MaternityOrParentalIncomeDetails
  ): boolean =>
    pipe(
      props.rework,
      option.chain(rework => rework.incomeInfo),
      option.chain(incomeInfo => incomeInfo.maternityOrParentalIncomeDetails),
      option.map(details => details[maternityField]),
      option.fold(
        () => false,
        () => true
      )
    );

  const monthlySocialBenefitReworked = reworkedMaternityLeave(
    "monthlySocialBenefit"
  );

  const allowanceTypeReworked = reworkedMaternityLeave("allowanceType");

  const disabled = !options.isEditing;
  const employmentTypeReworked = isReworked(
    props.rework,
    "contractInfo",
    "employmentType"
  );
  const incomeSourceReworked = isReworked(
    props.rework,
    "incomeInfo",
    "incomeSource"
  );
  const reworkDependent = employmentTypeReworked || incomeSourceReworked;

  return (
    <Stack column grow shrink units={6} className={classes.section}>
      <FormRow type="full">
        <AllowanceTypeDropdown
          fieldProps={{
            ...specialFieldsReworkFieldProps(
              fieldProps("allowanceType"),
              reworkDependent,
              allowanceTypeReworked,
              disabled,
              props.reworkAll
            ),
          }}
        />
      </FormRow>
      <FormRow type="full">
        <MoneyField
          {...specialFieldsReworkFieldProps(
            fieldProps("monthlySocialBenefit"),
            reworkDependent,
            monthlySocialBenefitReworked,
            disabled,
            props.reworkAll
          )}
          label={formatMessage(
            "StandardLoan.MaternityLeave.monthlySocialBenefit"
          )}
          currency={tenantCurrency}
          placeholder={formatMessage(
            "StandardLoan.MaternityLeave.monthlySocialBenefitPlaceholder"
          )}
        />
      </FormRow>
    </Stack>
  );
}
