import {
  FormRow,
  FormSection,
  NumberField,
  Stack,
  useForm,
  validators,
} from "design-system";
import { boolean, option, taskEither } from "fp-ts";
import {
  constFalse,
  constNull,
  constTrue,
  constUndefined,
  identity,
  pipe,
} from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { useEffect, useMemo, useRef, useState } from "react";
import { foldTenant, MonthYear, Tenant } from "../../../../globalDomain";
import { useFormatMessage } from "../../../../intl";
import { useAppContext } from "../../../../useAppContext";
import { CompanySuggestion } from "../../../../Common/CompanyField/domain";
import { useTenantCurrency } from "../../../../Common/useTenantCurrency";
import { useValidators } from "../../../../Common/useValidators";
import { YesNoRadioGroupField } from "../../../../Common/YesNoRadioGroup/YesNoRadioGroupField";
import { ReworkIncomeNoSourceOutput } from "../../../Rework/api";
import {
  isReworked,
  useReworkComparator,
} from "../../../Rework/useReworkComparator";
import * as api from "../../api";
import { AmountWithCurrencyField } from "../../commons/AmountWithCurrencyField";
import { CompanyInfoRow } from "../../commons/CompanyInfoRow";
import { CurrencyDropdown } from "../../commons/CurrencyDropdown";
import { EmploymentTypeDropdown } from "../../commons/EmploymentTypeDropdown";
import { JobPositionDropdown } from "../../commons/JobPositionsDropdown";
import { MonthlyIncome } from "../../commons/MonthlyIncomeField";
import { MonthYearField } from "../../commons/MonthYearField";
import { PaymentMethodRadiogroup } from "../../commons/PaymentMethodRadioGroup";
import {
  IncomeCardProps,
  useControlledSubmit,
} from "../../commons/useControlledSubmit";
import {
  constOptionNoneValidator,
  getCompanyIcoWithDefault,
  getCompanyNameWithDefault,
  getEmptyAllowanceInfo,
  getEmptyCompanyInfo,
  getEmptyContractInfo,
  getEmptyIncomeInfo,
  notInTheFutureMonthYearValidator,
  notInThePastMonthYearValidator,
  useUniqueCompanyValidators,
} from "../../commons/Utils";
import {
  EmployedProps,
  IncomeData,
  isOptionTrue,
  PaymentMethod,
  useFormatPaymentMethod,
} from "../../../../StandardLoan/IncomeForm/domain";
import * as classes from "./EmployedCard.treat";

type Props = IncomeCardProps & {
  abroad: boolean;
  rework: Option<ReworkIncomeNoSourceOutput>;
  reworkAll: boolean;
};

export function EmployedCard(props: Props) {
  const formatMessage = useFormatMessage();
  const { specialFieldsReworkFieldProps } = useReworkComparator(props.rework);

  const {
    nonNegativeNumber,
    validCompanyNameRequired,
    validCompanyIcoRequired,
    amountMinMax,
    definedNoExtract,
  } = useValidators();
  const {
    apiParameters: { tenant },
  } = useAppContext();
  const tenantCurrency = useTenantCurrency();
  const validatedValues = useRef<Option<IncomeData>>(option.none);
  const formatPaymentMethod = useFormatPaymentMethod();

  const syncCompanyFields = (suggestion: CompanySuggestion) =>
    setFormState({
      companyName: suggestion.name,
      companyIco: suggestion.id,
    });

  const currentYear = new Date().getFullYear();
  const incomeData = props.incomeData;
  const options = props.options;

  const startingDateMinYear = useMemo(
    () =>
      pipe(
        incomeData.incomeOptions.startDateYearOptions,
        option.map(years => years.sort((a, b) => a - b)[0]),
        option.getOrElse(() => currentYear - 50)
      ),
    [incomeData.incomeOptions.startDateYearOptions]
  );

  const [
    uniqueCompanyNameValidator,
    uniqueCompanyIcoValidator,
  ] = useUniqueCompanyValidators(options);

  const initialValues = {
    salaryCurrency: pipe(
      incomeData.incomeInfo,
      option.chain(data => data.salaryCurrency)
    ),
    monthlyIncome: pipe(
      incomeData.incomeInfo,
      option.chain(data => data.monthlyIncome),
      option.alt(() => incomeData.incomeOptions.incomeDefault)
    ) as Option<number>,
    companyName: getCompanyNameWithDefault(incomeData),
    companyIco: getCompanyIcoWithDefault(incomeData),
    applicantsShareHigherThan33: pipe(
      incomeData.companyInfo,
      option.chain(data => data.applicantsShareHigherThan33)
    ),
    startingDate: pipe(
      incomeData.contractInfo,
      option.chain(data => data.startingDate),
      option.altW(() => incomeData.incomeOptions.startDateEmploymentDefault),
      option.map(startingDate => {
        if (startingDate.year <= currentYear - 3) {
          return { year: startingDate.year, month: 12 } as MonthYear;
        }
        return startingDate;
      })
    ),
    jobPosition: pipe(
      incomeData.contractInfo,
      option.chain(data => data.jobPosition)
    ),
    employmentType: pipe(
      incomeData.contractInfo,
      option.chain(data => data.employmentType)
    ),
    inProbation: pipe(
      incomeData.contractInfo,
      option.chain(data => data.inProbation)
    ),
    contractIndefinitePeriod: pipe(
      incomeData.contractInfo,
      option.chain(data => data.contractIndefinitePeriod),
      option.alt(() => option.some(true))
    ),
    workBasedOnAgreementOnWorkingActivity: pipe(
      incomeData.contractInfo,
      option.chain(data => data.workBasedOnAgreementOnWorkingActivity)
    ),
    contractPeriodExpiration: pipe(
      incomeData.contractInfo,
      option.chain(data => data.contractPeriodExpiration)
    ),
    employedInSpecificProfessions: pipe(
      incomeData.contractInfo,
      option.chain(data => data.employedInSpecificProfessions)
    ),
    lengthOfUninterruptedOverallEmployment: pipe(
      incomeData.contractInfo,
      option.chain(data => data.lengthOfUninterruptedOverallEmployment)
    ) as Option<number>,
    allowanceAmount: pipe(
      incomeData.allowanceInfo,
      option.chain(data => data.allowanceAmount)
    ) as Option<number>,
    receivesAllowance: pipe(
      incomeData.allowanceInfo,
      option.chain(data => data.receivesAllowance),
      option.alt(() => option.some(false))
    ),
    paymentMethod: pipe(
      incomeData.contractInfo,
      option.chain(data => data.paymentMethod),
      option.alt(() =>
        foldTenant(
          tenant,
          () => option.none,
          () => option.some<PaymentMethod>("BankTransfer")
        )
      )
    ),
  };

  const showAllowanceSection = false;

  const [overallEmploymentShown, setOverallEmploymentShown] = useState(false);
  const [contractExpirationShown, setContractExpirationShown] = useState(false);
  const [
    contractIndefinitePeriodShown,
    setContractIndefinitePeriodShown,
  ] = useState(false);
  const [probationFieldShown, setProbationFieldShown] = useState(false);
  const [paymentMethodShown, setPaymentMethodShown] = useState(false);
  const [
    workBasedOnAgreementOnWorkingActivityShown,
    setWorkBasedOnAgreementOnWorkingActivityShown,
  ] = useState(false);

  const [companyNameIsValid, setCompanyNameIsValid] = useState(true);
  const [companyIcoIsValid, setCompanyIcoIsValid] = useState(true);
  const onCompanyNameValidationChange = (isValid: boolean) => {
    setCompanyNameIsValid(isValid);
  };
  const onCompanyIcoValidationChange = (isValid: boolean) => {
    setCompanyIcoIsValid(isValid);
  };

  const {
    fieldProps,
    setValues: setFormState,
    handleSubmit,
    resetForm,
  } = useForm(
    {
      initialValues: initialValues,
      fieldValidators: values => ({
        salaryCurrency: definedNoExtract(),
        monthlyIncome: validators.inSequence(
          definedNoExtract(),
          validators.validateIfDefined(nonNegativeNumber)
        ),
        companyName: pipe(
          props.abroad,
          boolean.fold(
            () =>
              validators.inSequence(
                validCompanyNameRequired(companyNameIsValid),
                uniqueCompanyNameValidator(values.companyName)
              ),
            constUndefined
          )
        ),
        companyIco: pipe(
          props.abroad,
          boolean.fold(
            () =>
              validators.inSequence(
                validCompanyIcoRequired(false, companyIcoIsValid),
                uniqueCompanyIcoValidator(values.companyIco)
              ),
            constUndefined
          )
        ),
        startingDate: notInTheFutureMonthYearValidator(
          formatMessage("StandardLoan.EmployedCard.startingDateError"),
          formatMessage("Form.fieldError.required")
        ),
        jobPosition: foldTenant(
          tenant,
          () => definedNoExtract(),
          constOptionNoneValidator
        ),
        employmentType: foldTenant(
          tenant,
          () => definedNoExtract(),
          constOptionNoneValidator
        ),
        inProbation: probationFieldShown
          ? definedNoExtract()
          : constOptionNoneValidator(),
        applicantsShareHigherThan33: validators.inSequence(
          definedNoExtract<boolean>(),
          validators.fromPredicate(
            share =>
              props.abroad ||
              !pipe(
                share,
                option.fold(() => false, identity)
              ),
            formatMessage("StandardLoan.EmployedCard.applicantShareError")
          )
        ),
        contractIndefinitePeriod: pipe(
          contractIndefinitePeriodShown,
          boolean.fold(constOptionNoneValidator, () =>
            definedNoExtract<boolean>()
          )
        ),
        workBasedOnAgreementOnWorkingActivity: foldTenant(
          tenant,
          constOptionNoneValidator,
          () =>
            pipe(
              workBasedOnAgreementOnWorkingActivityShown,
              boolean.fold(constOptionNoneValidator, () =>
                definedNoExtract<boolean>()
              )
            )
        ),
        contractPeriodExpiration: pipe(
          contractExpirationShown,
          boolean.fold(constOptionNoneValidator, () =>
            notInThePastMonthYearValidator(
              formatMessage(
                "StandardLoan.EmployedCard.contractPeriodExpirationError"
              ),
              formatMessage("Form.fieldError.required")
            )
          )
        ),
        employedInSpecificProfessions: pipe(
          contractExpirationShown,
          boolean.fold(constOptionNoneValidator, () =>
            definedNoExtract<boolean>()
          )
        ),
        lengthOfUninterruptedOverallEmployment: pipe(
          overallEmploymentShown,
          boolean.fold(constOptionNoneValidator, () =>
            validators.inSequence(
              definedNoExtract<number>(),
              validators.validateIfDefined(amountMinMax(0, 999))
            )
          )
        ),
        paymentMethod: paymentMethodShown
          ? definedNoExtract<PaymentMethod>()
          : constOptionNoneValidator(),
        receivesAllowance: foldTenant(tenant, constOptionNoneValidator, () =>
          definedNoExtract<boolean>()
        ),
        allowanceAmount: foldTenant(tenant, constOptionNoneValidator, () =>
          pipe(
            isOptionTrue(values.receivesAllowance),
            boolean.fold(constOptionNoneValidator, () =>
              validators.inSequence(
                definedNoExtract<number>(),
                validators.validateIfDefined(amountMinMax(0, 999999999))
              )
            )
          )
        ),
      }),
    },
    {
      onSubmit: values =>
        taskEither.fromIO(() => {
          validatedValues.current = option.some({
            ...incomeData,
            incomeInfo: option.some({
              ...getEmptyIncomeInfo(
                props.abroad ? "EmployedAbroad" : "Employed"
              ),
              salaryCurrency: values.salaryCurrency,
              monthlyIncome: values.monthlyIncome,
            }),
            companyInfo: option.some({
              ...getEmptyCompanyInfo(),
              companyName: pipe(
                values.companyName,
                NonEmptyString.is,
                boolean.fold(
                  () => option.none as Option<NonEmptyString>,
                  () => option.some(values.companyName as NonEmptyString)
                )
              ),
              companyIco: pipe(
                values.companyIco,
                NonEmptyString.is,
                boolean.fold(
                  () => option.none as Option<NonEmptyString>,
                  () => option.some(values.companyIco as NonEmptyString)
                )
              ),
              applicantsShareHigherThan33: values.applicantsShareHigherThan33,
            }),
            contractInfo: option.some({
              ...getEmptyContractInfo(),
              startingDate: values.startingDate,
              employmentType: values.employmentType,
              inProbation: values.inProbation,
              contractIndefinitePeriod: values.contractIndefinitePeriod,
              workBasedOnAgreementOnWorkingActivity:
                values.workBasedOnAgreementOnWorkingActivity,
              contractPeriodExpiration: values.contractPeriodExpiration,
              employedInSpecificProfessions:
                values.employedInSpecificProfessions,
              lengthOfUninterruptedOverallEmployment:
                values.lengthOfUninterruptedOverallEmployment,
              paymentMethod: values.paymentMethod,
              paymentMethodLabel: pipe(
                values.paymentMethod,
                option.map(paymentMethod => formatPaymentMethod(paymentMethod))
              ),
              jobPosition: values.jobPosition,
            }),
            allowanceInfo: option.some({
              ...getEmptyAllowanceInfo(),
              receivesAllowance: values.receivesAllowance,
              allowanceAmount: values.allowanceAmount,
            }),
          });
        }),
    }
  );

  useEffect(() => {
    const selectedCurrency = fieldProps("salaryCurrency").value;
    resetForm();
    fieldProps("salaryCurrency").onChange(selectedCurrency);
  }, [props.employmentType]);

  useControlledSubmit(props, handleSubmit, validatedValues);

  const disabled = !options.isEditing;

  const incomeSourceReworked = isReworked(
    props.rework,
    "incomeInfo",
    "incomeSource"
  );
  const reworkDependent = incomeSourceReworked;

  const monthlyIncome = (
    <MonthlyIncome
      fieldProps={specialFieldsReworkFieldProps(
        fieldProps("monthlyIncome"),
        reworkDependent,
        isReworked(props.rework, "incomeInfo", "monthlyIncome") &&
          option.isSome(initialValues.monthlyIncome),
        disabled,
        props.reworkAll
      )}
      currency={pipe(
        fieldProps("salaryCurrency").value,
        option.getOrElse<string>(() => tenantCurrency)
      )}
      description={formatMessage("StandardLoan.EmployedCard.monthlyIncomeInfo")}
      min={0}
      max={999999999}
    />
  );

  return (
    <Stack column grow shrink units={6}>
      <Stack column grow shrink units={6} className={classes.section}>
        <FormRow type="full">
          <CurrencyDropdown
            fieldProps={specialFieldsReworkFieldProps(
              fieldProps("salaryCurrency"),
              reworkDependent,
              isReworked(props.rework, "incomeInfo", "salaryCurrency"),
              disabled,
              props.reworkAll
            )}
            isMainIncome={props.isMainIncome}
          />
        </FormRow>
        <FormRow type="full">{monthlyIncome}</FormRow>
      </Stack>
      <FormSection
        heading={{
          title: formatMessage(
            "StandardLoan.EmployedCard.CompanySection.title"
          ),
        }}
      >
        {!props.abroad && (
          <CompanyInfoRow
            incomeSourceType="Employed"
            companyIcoFieldProps={specialFieldsReworkFieldProps(
              fieldProps("companyIco"),
              reworkDependent,
              isReworked(props.rework, "companyInfo", "companyIco") &&
                initialValues.companyIco != "",
              disabled,
              props.reworkAll
            )}
            companyNameFieldProps={specialFieldsReworkFieldProps(
              fieldProps("companyName"),
              reworkDependent,
              isReworked(props.rework, "companyInfo", "companyName") &&
                initialValues.companyName != "",
              disabled,
              props.reworkAll
            )}
            syncCompanyFields={syncCompanyFields}
            onCompanyNameValidationChange={onCompanyNameValidationChange}
            onCompanyIcoValidationChange={onCompanyIcoValidationChange}
          />
        )}
        <FormRow type="full">
          <YesNoRadioGroupField
            {...specialFieldsReworkFieldProps(
              fieldProps("applicantsShareHigherThan33"),
              reworkDependent,
              isReworked(
                props.rework,
                "companyInfo",
                "applicantsShareHigherThan33"
              ),
              disabled,
              props.reworkAll
            )}
            label={formatMessage("StandardLoan.EmployedCard.applicantShare")}
          />
        </FormRow>
      </FormSection>
      <ContractsForm
        fieldProps={fieldProps}
        minYear={startingDateMinYear}
        maxYear={currentYear}
        disabled={!options.isEditing}
        rework={props.rework}
        resetFlag={props.employmentType}
        reworkDependent={reworkDependent}
        abroad={props.abroad}
        overallEmploymentShown={setOverallEmploymentShown}
        contractExpirationShown={setContractExpirationShown}
        contractIndefinitePeriodShown={setContractIndefinitePeriodShown}
        workBasedOnAgreementOnWorkingActivityShown={
          setWorkBasedOnAgreementOnWorkingActivityShown
        }
        probationFieldShown={setProbationFieldShown}
        paymentMethodShown={setPaymentMethodShown}
        reworkAll={props.reworkAll}
      />
      {showAllowanceSection &&
        foldTenant(tenant, constNull, () => (
          <FormSection
            heading={{
              title: formatMessage(
                "StandardLoan.EmployedCard.AllowancesSection.title"
              ),
            }}
          >
            <FormRow type="full">
              <YesNoRadioGroupField
                {...specialFieldsReworkFieldProps(
                  fieldProps("receivesAllowance"),
                  reworkDependent,
                  isReworked(
                    props.rework,
                    "allowanceInfo",
                    "receivesAllowance"
                  ),
                  disabled,
                  props.reworkAll
                )}
                label={formatMessage(
                  "StandardLoan.EmployedCard.receivesAllowance"
                )}
              />
            </FormRow>
            {isOptionTrue(fieldProps("receivesAllowance").value) && (
              <FormRow type="full">
                <AmountWithCurrencyField
                  fieldProps={{
                    ...specialFieldsReworkFieldProps(
                      fieldProps("allowanceAmount"),
                      reworkDependent,
                      isReworked(
                        props.rework,
                        "allowanceInfo",
                        "allowanceAmount"
                      ),
                      disabled,
                      props.reworkAll
                    ),
                  }}
                  label={formatMessage(
                    "StandardLoan.EmployedCard.allowanceAmount"
                  )}
                  max={999999999}
                  min={0}
                  placeholder={formatMessage(
                    "StandardLoan.EmployedCard.allowanceAmountPlaceholder"
                  )}
                />
              </FormRow>
            )}
          </FormSection>
        ))}
    </Stack>
  );
}

function ContractsForm({
  fieldProps,
  minYear,
  maxYear,
  disabled,
  rework,
  resetFlag,
  reworkDependent,
  abroad,
  overallEmploymentShown,
  contractExpirationShown,
  contractIndefinitePeriodShown,
  workBasedOnAgreementOnWorkingActivityShown,
  probationFieldShown,
  paymentMethodShown,
  reworkAll,
}: EmployedProps & {
  minYear: number;
  maxYear: number;
  rework: Option<ReworkIncomeNoSourceOutput>;
  resetFlag?: any;
  reworkDependent: boolean;
  abroad: boolean;
  overallEmploymentShown: (visible: boolean) => unknown;
  contractExpirationShown: (visible: boolean) => unknown;
  contractIndefinitePeriodShown: (visible: boolean) => unknown;
  workBasedOnAgreementOnWorkingActivityShown: (visible: boolean) => unknown;
  probationFieldShown: (visible: boolean) => unknown;
  paymentMethodShown: (visible: boolean) => unknown;
  reworkAll: boolean;
}) {
  const formatMessage = useFormatMessage();
  const { specialFieldsReworkFieldProps } = useReworkComparator(rework);

  const {
    apiParameters: { tenant },
  } = useAppContext();

  const currentYear = new Date().getFullYear();

  const showContractExpirationDate = () => {
    const result =
      !abroad &&
      showContractExpiration(
        tenant,
        fieldProps("contractIndefinitePeriod").value,
        fieldProps("employmentType").value
      );
    contractExpirationShown(result);
    return result;
  };

  const showContractIndefinitePeriod = () => {
    const result =
      (!abroad && foldTenant(tenant, constFalse, constTrue)) ||
      (abroad &&
        foldTenant(tenant, constFalse, () => {
          return !pipe(
            fieldProps("inProbation").value,
            option.getOrElse(constTrue)
          );
        }));
    contractIndefinitePeriodShown(result);
    return result;
  };

  const showWorkBasedOnAgreement = () => {
    const result = foldTenant(tenant, constFalse, () => {
      return (
        !abroad ||
        (pipe(
          fieldProps("contractIndefinitePeriod").value,
          option.getOrElse(constFalse)
        ) &&
          !option.isNone(fieldProps("inProbation").value) &&
          !pipe(fieldProps("inProbation").value, option.getOrElse(constFalse)))
      );
    });
    workBasedOnAgreementOnWorkingActivityShown(result);
    return result;
  };

  const expirationIsIn24MonthFromStartingDate = (
    starting: MonthYear,
    expiration: MonthYear
  ) => {
    return (
      new Date(expiration.year, expiration.month) <
      new Date(starting.year + 2, starting.month)
    );
  };

  const showLengthOfOverallEmploymentField = () => {
    const result =
      showContractExpirationDate() &&
      !abroad &&
      !isOptionTrue(fieldProps("employedInSpecificProfessions").value) &&
      pipe(
        fieldProps("startingDate").value,
        option.fold(constFalse, startMonthYear =>
          pipe(
            fieldProps("contractPeriodExpiration").value,
            option.fold(constFalse, expirationMonthYear =>
              expirationIsIn24MonthFromStartingDate(
                startMonthYear,
                expirationMonthYear
              )
            )
          )
        )
      );
    overallEmploymentShown(result);
    return result;
  };

  const showPaymentMethod = () => {
    const result =
      !abroad ||
      foldTenant(
        tenant,
        () =>
          pipe(
            fieldProps("employmentType").value,
            option.fold(
              constFalse,
              employmentType => employmentType !== "LimitedContract"
            )
          ),
        () =>
          showContractIndefinitePeriod() &&
          pipe(
            fieldProps("contractIndefinitePeriod").value,
            option.getOrElse(constFalse)
          )
      );
    paymentMethodShown(result);
    return result;
  };

  const showProbationField = () => {
    const result = foldTenant(
      tenant,
      () => {
        return (
          !abroad ||
          pipe(
            fieldProps("employmentType").value,
            option.fold(
              constFalse,
              employmentType => employmentType !== "LimitedContract"
            )
          )
        );
      },
      constTrue
    );
    probationFieldShown(result);
    return result;
  };

  const contractTypeReworked = isReworked(
    rework,
    "contractInfo",
    "contractIndefinitePeriod"
  ); //for CZ

  const startingDateReworked = isReworked(
    rework,
    "contractInfo",
    "startingDate"
  );

  const employmentTypeReworked = isReworked(
    rework,
    "contractInfo",
    "employmentType"
  ); //for SK

  const defaultMonth = 11;

  return (
    <FormSection
      heading={{
        title: formatMessage(
          "StandardLoan.EmployedCard.ContractsSection.title"
        ),
      }}
    >
      <FormRow type="full">
        <MonthYearField
          {...specialFieldsReworkFieldProps(
            fieldProps("startingDate"),
            reworkDependent,
            isReworked(rework, "contractInfo", "startingDate"),
            !!disabled,
            reworkAll
          )}
          clearable={false}
          label={formatMessage(
            "StandardLoan.EmployedCard.Contract.startingDate"
          )}
          maxYear={maxYear}
          minYear={minYear}
          searchable
          monthSelectionYearLimit={3}
          checkMonthLimit
          resetFlag={resetFlag}
          defaultMonth={defaultMonth}
        />
      </FormRow>
      {foldTenant(
        tenant,
        () => (
          <FormRow type="full">
            <JobPositionDropdown
              fieldProps={specialFieldsReworkFieldProps(
                fieldProps("jobPosition"),
                reworkDependent,
                isReworked(rework, "contractInfo", "jobPosition"),
                !!disabled,
                reworkAll
              )}
              disabled={
                !reworkAll &&
                (disabled ||
                  (option.isSome(rework) &&
                    !reworkDependent &&
                    !isReworked(rework, "contractInfo", "jobPosition")))
              }
            />
          </FormRow>
        ),
        constNull
      )}
      {foldTenant(
        tenant,
        () => (
          <FormRow type="full">
            <EmploymentTypeDropdown
              fieldProps={specialFieldsReworkFieldProps(
                fieldProps("employmentType"),
                reworkDependent,
                isReworked(rework, "contractInfo", "employmentType"),
                !!disabled,
                reworkAll
              )}
            />
          </FormRow>
        ),
        constNull
      )}
      {showProbationField() && (
        <FormRow type="full">
          <YesNoRadioGroupField
            {...specialFieldsReworkFieldProps(
              fieldProps("inProbation"),
              reworkDependent,
              isReworked(rework, "contractInfo", "inProbation"),
              !!disabled,
              reworkAll
            )}
            label={formatMessage("StandardLoan.EmployedCard.inProbation")}
          />
        </FormRow>
      )}
      {showContractIndefinitePeriod() && (
        <FormRow type="full">
          <YesNoRadioGroupField
            {...specialFieldsReworkFieldProps(
              fieldProps("contractIndefinitePeriod"),
              reworkDependent,
              isReworked(rework, "contractInfo", "contractIndefinitePeriod"),
              !!disabled,
              reworkAll
            )}
            label={formatMessage(
              "StandardLoan.EmployedCard.contractIndefinitePeriod"
            )}
          />
        </FormRow>
      )}
      {showContractExpirationDate() && (
        <FormRow type="full">
          <MonthYearField
            {...specialFieldsReworkFieldProps(
              fieldProps("contractPeriodExpiration"),
              reworkDependent || contractTypeReworked || employmentTypeReworked,
              isReworked(rework, "contractInfo", "contractPeriodExpiration"),
              !!disabled,
              reworkAll
            )}
            clearable={false}
            label={formatMessage(
              "StandardLoan.EmployedCard.contractPeriodExpiration"
            )}
            maxYear={currentYear + 50}
            minYear={currentYear}
            searchable
            ascending
            checkMonthLimit={false}
            monthSelectionYearLimit={0}
          />
        </FormRow>
      )}
      {showContractExpirationDate() && (
        <FormRow type="full">
          <YesNoRadioGroupField
            {...specialFieldsReworkFieldProps(
              fieldProps("employedInSpecificProfessions"),
              reworkDependent || contractTypeReworked || employmentTypeReworked,
              isReworked(
                rework,
                "contractInfo",
                "employedInSpecificProfessions"
              ),
              !!disabled,
              reworkAll
            )}
            label={formatMessage(
              "StandardLoan.EmployedCard.employedInSpecificProfessions"
            )}
          />
        </FormRow>
      )}
      {showLengthOfOverallEmploymentField() && (
        <FormRow type="full">
          <NumberField
            {...specialFieldsReworkFieldProps(
              fieldProps("lengthOfUninterruptedOverallEmployment"),
              reworkDependent ||
                contractTypeReworked ||
                employmentTypeReworked ||
                startingDateReworked,
              isReworked(
                rework,
                "contractInfo",
                "lengthOfUninterruptedOverallEmployment"
              ),
              !!disabled,
              reworkAll
            )}
            label={formatMessage(
              "StandardLoan.EmployedCard.lengthOfUninterruptedOverallEmployment"
            )}
            max={999}
            min={0}
            placeholder={formatMessage(
              "StandardLoan.EmployedCard.lengthOfUninterruptedOverallEmploymentPlaceholder"
            )}
            rightContent={option.none}
          />
        </FormRow>
      )}

      {showWorkBasedOnAgreement() && (
        <FormRow type="full">
          <YesNoRadioGroupField
            {...specialFieldsReworkFieldProps(
              fieldProps("workBasedOnAgreementOnWorkingActivity"),
              reworkDependent,
              isReworked(
                rework,
                "contractInfo",
                "workBasedOnAgreementOnWorkingActivity"
              ),
              !!disabled,
              reworkAll
            )}
            label={formatMessage(
              "StandardLoan.EmployedCard.workBasedOnAgreementOnWorkingActivity"
            )}
          />
        </FormRow>
      )}
      {showPaymentMethod() && (
        <PaymentMethodRadiogroup
          fieldProps={specialFieldsReworkFieldProps(
            fieldProps("paymentMethod"),
            reworkDependent,
            isReworked(rework, "contractInfo", "paymentMethod"),
            !!disabled,
            reworkAll
          )}
        />
      )}
    </FormSection>
  );
}

function showContractExpiration(
  tenant: Tenant,
  contractIndefinitePeriod: Option<boolean>,
  employmentType: Option<api.EmploymentType>
) {
  return foldTenant(
    tenant,
    () =>
      pipe(
        employmentType,
        option.fold(constFalse, type => type !== "IndefiniteContract")
      ),
    () =>
      pipe(
        contractIndefinitePeriod,
        option.getOrElse(() => true)
      ) === false
  );
}
