import cx from "classnames";
import {
  ContentRow,
  ErrorBanner,
  FeedbackBlock,
  Loader,
  Stack,
} from "design-system";
import * as classes from "./EmptyPanel.treat";
import { boolean, option, taskEither } from "fp-ts";
import { IO } from "fp-ts/IO";
import { Option } from "fp-ts/Option";
import { constFalse, constVoid, pipe } from "fp-ts/function";
import {
  ExistingClientAuthenticationMethod,
  foldExistingClientAuthenticationMethod,
  genericError,
} from "../../../globalDomain";
import { useFormatMessage } from "../../../intl";
import * as remoteData from "../../../RemoteData";
import { useAPI, useCommand, useQuery } from "../../../useAPI";
import { PreContractualDocuments } from "../../../Common/PreContractualDocuments/PreContractualDocuments";
import { OtpGenerationInput } from "../../../OTP/domain";
import * as api from "../../api";
import { StandardLoanFlowType } from "../../domain";
import { SignatureProps } from "../../../Common/PreContractualDocuments/DocumentsSignature";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import { Path } from "../../../APICall";
import * as smartKeyApi from "../../../SmartKey/api";
import { useDocumentStateFold } from "../useDocumentStateFold";
import { CheckingContractsWithStatusCheck } from "./CheckingContractsWithStatusCheck";
import { CheckingContractsLoader } from "./CheckingContractsLoader";
import { useEffect, useState } from "react";

type Props = {
  onContinue: (status: Option<api.LFStatus>) => unknown;
  onApplicationRejected: IO<void>;
  onAfterSignature: (status: Option<api.LFStatus>) => void;
  flowType: StandardLoanFlowType;
  authenticationMethod: Option<ExistingClientAuthenticationMethod>;
  mustReadAllDocuments?: boolean;
  skipContracts: boolean;
  existingClient: boolean;
  isCredentialsAndSigningRemote: boolean;
  isRiskyClient: boolean;
};

function CheckingContractsWithDocuments(props: Props) {
  const formatMessage = useFormatMessage();
  const [lfStatus, setLfStatus] = useState<Option<api.LFStatus>>(option.none);

  const { foldDocumentState, documentState } = useDocumentStateFold({
    signingRemoteContractsAlreadyPrepared: props.isCredentialsAndSigningRemote,
  });

  const [preContractualDocuments] = useAPI(api.preContractualDocuments);
  const [getTermsAndConditions] = useAPI(api.termsAndConditions);
  const contractRead = useCommand(api.contractRead);
  const marketingConsent = useCommand(api.marketingConsent);
  const termsAndConditionsReceived = useCommand(api.termsAndConditionsReceived);
  const authorizeWithOTP = useCommand(api.authorizeWithOTP);
  const verifyOtpCommand = useCommand(api.checkAuthorizationOTP);
  const checkApplicationStatus = useCommand(api.checkApplicationStatus);
  const [phoneNumber] = useQuery(api.phoneNumber);

  const generateOtpCommand = (input: OtpGenerationInput) =>
    pipe(
      authorizeWithOTP(input),
      taskEither.chain(response => {
        if (!response.authorize) {
          props.onApplicationRejected();
          return taskEither.left(genericError);
        }
        setLfStatus(response.lfStatus);
        return taskEither.right({
          ...response,
          transactionId: option.some(response.transactionId),
        });
      })
    );

  const authorizeWithPushCommand = useCommand(api.authorizeWithPush);

  const generateAuthorizationQRCommand = useCommand(
    api.generateAuthorizationQR
  );

  const checkAuthorizationPushPath: Path = [
    "packages",
    "loans",
    "standard-loan",
    "checkAuthorizationPush",
  ];

  const checkAuthorizationQRPath: Path = [
    "packages",
    "loans",
    "standard-loan",
    "checkAuthorizationQR",
  ];

  useEffect(() => {
    if (documentState === "KycRejected") {
      props.onApplicationRejected();
    }
  }, [documentState]);

  const authorizeWithPush: ReaderTaskEither<
    void,
    unknown,
    smartKeyApi.SendPushOutput
  > = () =>
    pipe(
      authorizeWithPushCommand(),
      taskEither.chain(response =>
        taskEither.fromIO(() => {
          if (response.applicationRejected) {
            props.onApplicationRejected();
          }

          return response;
        })
      )
    );

  const generateAuthorizationQR: ReaderTaskEither<
    void,
    unknown,
    smartKeyApi.GenerateQRCodeOutput
  > = () =>
    pipe(
      generateAuthorizationQRCommand(),
      taskEither.chain(response => {
        if (api.GenerateQRCodeError.is(response)) {
          if (response.error === "ApplicationRejected") {
            props.onApplicationRejected();
            return taskEither.left(null);
          }

          return taskEither.right({
            ...response,
            error: "UserBlocked",
          });
        } else {
          return taskEither.right(response);
        }
      })
    );

  const authMethod = pipe(
    props.authenticationMethod,
    option.getOrElse<ExistingClientAuthenticationMethod>(() => "ID")
  );

  const [authorizationError, setAuthorizationError] = useState(false);

  const canStartPinAuthorization = pipe(
    checkApplicationStatus(),
    taskEither.fold(
      () => {
        setAuthorizationError(true);
        return taskEither.fromIO(constFalse);
      },
      ({ rejected }) =>
        taskEither.fromIO(() => {
          if (rejected) {
            props.onApplicationRejected();
          }
          setAuthorizationError(false);
          return !rejected;
        })
    )
  );

  const renderContent = pipe(
    phoneNumber,
    remoteData.map(result => result.phoneNumber),
    remoteData.fold(
      () => <Loader />,
      () => (
        <ContentRow type="lateral-margins">
          <ErrorBanner children={formatMessage("GenericError")} />
        </ContentRow>
      ),
      phoneNumber => (
        <Stack column units={4}>
          <ContentRow type="full" className={cx(classes.content)}>
            <PreContractualDocuments
              inlineReadingCheckbox
              showReadingConfirm={
                props.flowType !== "TLSAgent" && !props.skipContracts
              }
              showMarketingConsent={
                props.flowType !== "TLSAgent" && !props.skipContracts
              }
              showContractsAgreement={
                props.flowType !== "TLSAgent" && !props.skipContracts
              }
              promotion={option.none}
              onContinue={() => props.onContinue(lfStatus)}
              onContinueRemoteSigning={props.onContinue}
              onAfterSignature={() => props.onAfterSignature(lfStatus)}
              signatureProps={pipe(authMethod, authMethod =>
                foldExistingClientAuthenticationMethod<SignatureProps>(
                  authMethod,
                  {
                    ID: () => ({
                      authenticationMethod: "ID",
                      phoneNumber,
                      generateOtpCommand,
                      onSignFailure: constVoid,
                      verifyOtpCommand,
                    }),
                    PUSH_NOTIFICATION: () => ({
                      authenticationMethod: "PUSH_NOTIFICATION",
                      authorizeWithPush,
                      checkAuthorizationPushPath,
                      checkAuthorizationQRPath,
                      generateAuthorizationQR,
                      onFailedSignature: constVoid,
                    }),
                    QR: () => ({
                      authenticationMethod: "QR",
                      authorizeWithPush,
                      checkAuthorizationPushPath,
                      checkAuthorizationQRPath,
                      generateAuthorizationQR,
                      onFailedSignature: constVoid,
                    }),
                    EXTERNAL_PUSH_NOTIFICATION: () => ({
                      authenticationMethod: "EXTERNAL_PUSH_NOTIFICATION",
                      onApplicationRejected: props.onApplicationRejected,
                    }),
                    PIN: () => ({
                      authenticationMethod: "PIN",
                      beforeAuthorizationAction: constVoid,
                      checkAuthorizationPushPath,
                      canStartAuthorization: canStartPinAuthorization,
                    }),
                  }
                )
              )}
              onReadContract={docId =>
                contractRead({ docId, documentRead: true })
              }
              onUnreadContract={docId =>
                contractRead({ docId, documentRead: false })
              }
              onTermsAndConditionsReceived={termsAndConditionsReceived()}
              onMarketingConsentExpressed={accepted =>
                marketingConsent({ accepted })
              }
              getDocuments={preContractualDocuments}
              getTermsAndConditions={option.some(getTermsAndConditions)}
              showBackButton={false}
              onBack={constVoid}
              mustReadAllDocuments={props.mustReadAllDocuments}
              skipContracts={props.skipContracts}
              hideDocumentsSignatureBox={
                props.flowType === "TLSAgent" && !props.existingClient
              }
              startCheckingRemoteSignature={
                props.flowType === "TLSAgent" && !props.existingClient
              }
              shouldVerifySignatureStatus={
                authMethod !== "EXTERNAL_PUSH_NOTIFICATION"
              }
            />
          </ContentRow>
          {authorizationError && (
            <ContentRow type="lateral-margins">
              <ErrorBanner children={formatMessage("GenericError")} />
            </ContentRow>
          )}
        </Stack>
      )
    )
  );
  return foldDocumentState(
    () => (
      <CheckingContractsLoader
        status="loading"
        flowType={props.flowType}
        isRiskyClient={false}
      />
    ),
    () => renderContent,
    () => (
      <FeedbackBlock
        type="negative"
        heading={formatMessage(
          "StandardLoan.FollowUpAndSignature.documentCheckFail"
        )}
        subheading={option.none}
        size="large"
        actions={[]}
      />
    ),
    () => null
  );
}

export const CheckingContracts = (props: Props) => {
  return pipe(
    props.existingClient,
    boolean.fold(
      () => (
        <CheckingContractsWithStatusCheck
          content={() => <CheckingContractsWithDocuments {...props} />}
          feedbackBlock={(status, errId) => (
            <CheckingContractsLoader
              status={status}
              flowType={props.flowType}
              isRiskyClient={
                props.isRiskyClient ||
                pipe(
                  errId,
                  option.exists(error => error === "RiskyClient")
                )
              }
            />
          )}
        />
      ),
      () => <CheckingContractsWithDocuments {...props} />
    )
  );
};
