import { IO } from "fp-ts/IO";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { Reader } from "fp-ts/Reader";
import { MutableRefObject, useEffect } from "react";
import { SelectedCompany } from "../../domain";
import { IncomeData } from "../domain";
import { TaskEither } from "fp-ts/TaskEither";
import { option, taskEither } from "fp-ts";
import { ReworkIncomeNoSourceOutput } from "../../Rework/api";

export type IncomeFormOptions = {
  isEditing: boolean;
  alreadySelectedCompanies: Option<SelectedCompany[]>;
};

export type IncomeCardProps = {
  canSubmit: boolean;
  isMainIncome: boolean;
  onDataReady: Reader<IncomeData, unknown>;
  onFailure: IO<unknown>;
  incomeData: IncomeData;
  options: IncomeFormOptions;
  rework: Option<ReworkIncomeNoSourceOutput>;
  employmentType: any;
  reworkAll: boolean;
};

export function useControlledSubmit(
  props: IncomeCardProps,
  handleSubmit: TaskEither<unknown, unknown>,
  valuesRef: MutableRefObject<Option<IncomeData>>
) {
  useEffect(() => {
    if (!props.canSubmit) {
      return;
    }

    setTimeout(
      () =>
        pipe(
          handleSubmit,
          taskEither.chain(() =>
            taskEither.fromIO(() =>
              pipe(
                valuesRef.current,
                option.fold(() => {
                  throw new Error();
                }, props.onDataReady)
              )
            )
          ),
          taskEither.orElse(() => taskEither.fromIO(props.onFailure))
        )(),
      100
    );
  }, [props.canSubmit]);
}
