import * as t from "io-ts";
import { TaskEither } from "fp-ts/TaskEither";
import { Reader } from "fp-ts/Reader";
import {
  ComputedFieldProps,
  LocalizedString,
  NonNegative,
  PositiveInteger,
} from "design-system";
import { Currency, MonthYear, optionFromUndefined } from "../../globalDomain";
import { nonEmptyArray } from "io-ts-types/lib/nonEmptyArray";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { Option } from "fp-ts/Option";
import * as api from "./api";
import { useFormatMessage } from "../../intl";
import { option } from "fp-ts";
import { constFalse, pipe } from "fp-ts/function";
import { TypeOfRent } from "./Forms/CompanyOwnerForm/IncomeSection/domain";
import { PensionType } from "./Forms/PensionerForm/domain";

export const StandardIncomeSourceType = t.keyof(
  {
    Employed: true,
    Freelancer: true,
    EmployedAbroad: true,
    CompanyOwner: true,
    TradesmanCoOperatingPerson: true,
  },
  "StandardIncomeSourceType"
);

export type StandardIncomeSourceType = t.TypeOf<
  typeof StandardIncomeSourceType
>;

export const SpecialType = t.keyof(
  {
    SpecialType: true,
  },
  "SpecialType"
);

export type SpecialType = t.TypeOf<typeof SpecialType>;

export const IncomeSourceType = t.union(
  [StandardIncomeSourceType, SpecialType],
  "IncomeSourceType"
);

export type IncomeSourceType = t.TypeOf<typeof IncomeSourceType>;

export const SpecialIncomeSourceTypeCZ = t.keyof(
  {
    Alimony: true,
    MaternityLeave: true,
    Pensioner: true,
  },
  "SpecialIncomeSourceTypeCZ"
);

export type SpecialIncomeSourceTypeCZ = t.TypeOf<
  typeof SpecialIncomeSourceTypeCZ
>;

export const SpecialIncomeSourceTypeSK = t.keyof(
  {
    MaternityLeave: true,
    Pensioner: true,
  },
  "SpecialIncomeSourceTypeSK"
);

export type SpecialIncomeSourceTypeSK = t.TypeOf<
  typeof SpecialIncomeSourceTypeSK
>;

export const SpecialIncomeSourceType = t.union(
  [SpecialIncomeSourceTypeCZ, SpecialIncomeSourceTypeSK],
  "SpecialIncomeSourceType"
);

export type SpecialIncomeSourceType = t.TypeOf<typeof SpecialIncomeSourceType>;

const FlattenedIncomeSourceType = t.union(
  [StandardIncomeSourceType, SpecialIncomeSourceType],
  "FlattenedIncomeSourceType"
);

export type FlattenedIncomeSourceType = t.TypeOf<
  typeof FlattenedIncomeSourceType
>;

export const AllowanceInfo = t.type(
  {
    allowanceAmount: optionFromUndefined(NonNegative),
    receivesAllowance: optionFromUndefined(t.boolean),
  },
  "AllowanceInfo"
);

export type AllowanceInfo = t.TypeOf<typeof AllowanceInfo>;

export const PaymentMethodSK = t.keyof(
  {
    SalarySentUCB: true,
    SalarySentOtherBank: true,
    PaymentInCash: true,
  },
  "PaymentMethodSK"
);

export type PaymentMethodSK = t.TypeOf<typeof PaymentMethodSK>;

export const PaymentMethodCZ = t.keyof(
  {
    Cash: true,
    BankTransfer: true,
  },
  "PaymentMethodCZ"
);

export type PaymentMethodCZ = t.TypeOf<typeof PaymentMethodCZ>;

export const PaymentMethod = t.union(
  [PaymentMethodCZ, PaymentMethodSK],
  "PaymentMethod"
);

export type PaymentMethod = t.TypeOf<typeof PaymentMethod>;

export const CompanyInfo = t.type(
  {
    applicantsShareHigherThan33: optionFromUndefined(t.boolean),
    businessSector: optionFromUndefined(NonEmptyString),
    businessStartingDate: optionFromUndefined(MonthYear),
    companyIco: optionFromUndefined(NonEmptyString),
    companyName: optionFromUndefined(NonEmptyString),
    equityPositive: optionFromUndefined(t.boolean),
    freelancerType: optionFromUndefined(NonEmptyString),
    nameOf3P: optionFromUndefined(t.string), // ?
    stakeInCompany: optionFromUndefined(t.number),
    entrepreneurIco: optionFromUndefined(NonEmptyString),
    nameOfEntrepreneur: optionFromUndefined(t.string),
    addIco: optionFromUndefined(t.boolean),
    country: optionFromUndefined(t.string),
    phone: optionFromUndefined(t.string),
    city: optionFromUndefined(t.string),
    street: optionFromUndefined(t.string),
    houseNr: optionFromUndefined(NonEmptyString),
    zipCode: optionFromUndefined(NonEmptyString),
  },
  "CompanyInfo"
);

export type CompanyInfo = t.TypeOf<typeof CompanyInfo>;

export const ContractInfo = t.type(
  {
    alsoEmployeeOfTheCompany: optionFromUndefined(t.boolean),
    contractIndefinitePeriod: optionFromUndefined(t.boolean),
    contractPeriodExpiration: optionFromUndefined(MonthYear),
    employedInSpecificProfessions: optionFromUndefined(t.boolean),
    employmentType: optionFromUndefined(api.EmploymentType),
    inProbation: optionFromUndefined(t.boolean),
    lengthOfUninterruptedCurrentEmployment: optionFromUndefined(NonNegative),
    lengthOfUninterruptedOverallEmployment: optionFromUndefined(NonNegative),
    paymentMethod: optionFromUndefined(PaymentMethod),
    paymentMethodLabel: optionFromUndefined(LocalizedString),
    startingDate: optionFromUndefined(MonthYear),
    workBasedOnAgreementOnWorkingActivity: optionFromUndefined(t.boolean),
    jobPosition: optionFromUndefined(NonEmptyString), // SPECIFIC TYPE?
  },
  "ContractInfo"
);

export type ContractInfo = t.TypeOf<typeof ContractInfo>;

export const AlimonyIncomeDetails = t.type(
  {
    alimonyChildrenInvolved: optionFromUndefined(NonNegative),
    alimonyIdentifiable: optionFromUndefined(t.boolean),
    alimonyOutsideCountry: optionFromUndefined(t.boolean),
    monthlyAlimonyIncome: optionFromUndefined(NonNegative),
    alimonyCurrency: optionFromUndefined(t.string),
  },
  "AlimonyIncomeDetails"
);

export type AlimonyIncomeDetails = t.TypeOf<typeof AlimonyIncomeDetails>;

export const MaternityOrParentalIncomeDetails = t.type(
  {
    allowanceType: optionFromUndefined(api.AllowanceType),
    monthlySocialBenefit: optionFromUndefined(NonNegative),
  },
  "MaternityOrParentalIncomeDetails"
);

export type MaternityOrParentalIncomeDetails = t.TypeOf<
  typeof MaternityOrParentalIncomeDetails
>;

export const PensionerIncomeDetails = t.type(
  {
    monthlyPension: optionFromUndefined(NonNegative),
    monthlyRent: optionFromUndefined(NonNegative),
    pensionType: optionFromUndefined(PensionType),
  },
  "PensionerIncomeDetails"
);

export type PensionerIncomeDetails = t.TypeOf<typeof PensionerIncomeDetails>;

const StandardIncomeSourceInfo = t.type(
  {
    incomeSource: StandardIncomeSourceType,
  },
  "StandardIncomeSourceInfo"
);

export type StandardIncomeSourceInfo = t.TypeOf<
  typeof StandardIncomeSourceInfo
>;

const SpecialIncomeSourceInfo = t.type({
  incomeSource: SpecialType,
  specialTypeOfIncome: SpecialIncomeSourceType,
});

type SpecialIncomeSourceInfo = t.TypeOf<typeof SpecialIncomeSourceInfo>;

const IncomeSourceInfo = t.union(
  [StandardIncomeSourceInfo, SpecialIncomeSourceInfo],
  "IncomeSourceInfo"
);

export type IncomeSourceInfo = t.TypeOf<typeof IncomeSourceInfo>;

export const IncomeInfoNoSource = t.type(
  {
    alimonyIncomeDetails: optionFromUndefined(AlimonyIncomeDetails),
    amortizationOfRentedRe: optionFromUndefined(t.number),
    annualGrossIncome: optionFromUndefined(NonNegative),
    bruttoComissions: optionFromUndefined(NonNegative),
    businessGrossIncome: optionFromUndefined(t.number),
    currency: optionFromUndefined(Currency),
    employedInCompany: optionFromUndefined(t.boolean),
    equityFromLastYear: optionFromUndefined(t.number),
    grossIncomes: optionFromUndefined(t.number),
    incomeAsDeductibleExpenses: optionFromUndefined(t.boolean),
    incomeDescription: optionFromUndefined(t.string), // Localized string
    incomeFromRentContract: optionFromUndefined(t.number),
    incomeSourceLabel: optionFromUndefined(LocalizedString),
    isApplicantDeclareAsUserInDeclaration: optionFromUndefined(t.boolean),
    isCooperativeOwnership: optionFromUndefined(t.boolean),
    keepsAccountancy: optionFromUndefined(t.boolean),
    maternityOrParentalIncomeDetails: optionFromUndefined(
      MaternityOrParentalIncomeDetails
    ),
    monthlyIncome: optionFromUndefined(NonNegative),
    negativeBusinessProfit: optionFromUndefined(t.boolean),
    pensionerIncomeDetails: optionFromUndefined(PensionerIncomeDetails),
    profitForLastPeriod: optionFromUndefined(NonNegative),
    isProfitPeriodPositive: optionFromUndefined(t.boolean),
    profitSharingIncome: optionFromUndefined(t.boolean),
    r101BruttoIncomes: optionFromUndefined(t.number),
    r201IncomeFromLease: optionFromUndefined(t.number),
    r37PartialTaxBase: optionFromUndefined(t.number),
    r38PartialTaxBase: optionFromUndefined(t.number),
    r39PartialTaxBase: optionFromUndefined(t.number),
    r74TaxAfterClaimedRelief: optionFromUndefined(t.number),
    reCoOwned: optionFromUndefined(t.boolean),
    reInPersonalOwnership: optionFromUndefined(t.boolean),
    reUsedAsCollateral: optionFromUndefined(t.boolean),
    salaryCurrency: optionFromUndefined(t.string),
    sumOfPaidShareOfProfit: optionFromUndefined(NonNegative),
    tax: optionFromUndefined(t.number), // @TODO: Check with specs because requested limits are -999999999, 999999999
    taxBase: optionFromUndefined(t.number), // @TODO: Check with specs because requested limits are -999999999, 999999999
    typeOfIncome: optionFromUndefined(t.string), // SPECIFIC TYPE?
    typeOfRent: optionFromUndefined(TypeOfRent),
    youngPeopleConditions: optionFromUndefined(t.boolean),
  },
  "IncomeInfoNoSource"
);
type IncomeInfoNoSource = t.TypeOf<typeof IncomeInfoNoSource>;

export const IncomeInfo = t.intersection(
  [IncomeInfoNoSource, IncomeSourceInfo],
  "IncomeInfo"
);
export type IncomeInfo = t.TypeOf<typeof IncomeInfo>;

export function foldIncomeBySource<T>(matches: {
  whenStandard: Reader<IncomeInfoNoSource & StandardIncomeSourceInfo, T>;
  whenSpecial: Reader<IncomeInfoNoSource & SpecialIncomeSourceInfo, T>;
}): Reader<IncomeInfo, T> {
  return info => {
    switch (info.incomeSource) {
      case "SpecialType":
        return matches.whenSpecial(info);
      default:
        return matches.whenStandard(info);
    }
  };
}

export const IncomeOutput = t.type(
  {
    allowanceInfo: optionFromUndefined(AllowanceInfo),
    companyInfo: optionFromUndefined(CompanyInfo),
    contractInfo: optionFromUndefined(ContractInfo),
    incomeInfo: optionFromUndefined(IncomeInfo),
    uniqueId: optionFromUndefined(NonEmptyString),
    hasPreapprovedLimits: t.boolean,
  },
  "IncomeOutput"
);

export type IncomeOutput = t.TypeOf<typeof IncomeOutput>;

export const IncomeOptionsOutput = t.type(
  {
    companyIcoDefault: optionFromUndefined(t.string),
    companyNameDefault: optionFromUndefined(t.string),
    incomeDefault: optionFromUndefined(NonNegative),
    sourceOfIncomeDefault: optionFromUndefined(IncomeSourceType),
    sourceOfIncomeOptions: nonEmptyArray(IncomeSourceType),
    specialTypeOfIncomeDefault: optionFromUndefined(SpecialIncomeSourceType),
    specialTypeOfIncomeOptions: optionFromUndefined(
      t.array(SpecialIncomeSourceType)
    ), // Can be empty
    startDateEmploymentDefault: optionFromUndefined(MonthYear),
    startDateMonthOptions: optionFromUndefined(nonEmptyArray(PositiveInteger)),
    startDateYearOptions: optionFromUndefined(nonEmptyArray(PositiveInteger)),
    hasPreapprovedLimits: t.boolean,
  },
  "IncomeOptionsOutput"
);

export type IncomeOptionsOutput = t.TypeOf<typeof IncomeOptionsOutput>;

export const IncomeData = t.intersection(
  [
    t.type({
      incomeSourceList: nonEmptyArray(IncomeSourceType),
      specialIncomeSourceList: optionFromUndefined(
        t.array(SpecialIncomeSourceType)
      ),
      incomeOptions: IncomeOptionsOutput,
    }),
    IncomeOutput,
  ],
  "IncomeData"
);

export type IncomeData = t.TypeOf<typeof IncomeData>;

export type EmployedCardFormData = {
  salaryCurrency: Option<string>;
  monthlyIncome: Option<number>;
  companyName: string;
  companyIco: string;
  applicantsShareHigherThan33: Option<boolean>;
  startingDate: Option<MonthYear>;
  jobPosition: Option<NonEmptyString>;
  employmentType: Option<api.EmploymentType>;
  inProbation: Option<boolean>;
  contractIndefinitePeriod: Option<boolean>;
  workBasedOnAgreementOnWorkingActivity: Option<boolean>;
  contractPeriodExpiration: Option<MonthYear>;
  employedInSpecificProfessions: Option<boolean>;
  lengthOfUninterruptedOverallEmployment: Option<number>;
  allowanceAmount: Option<number>;
  receivesAllowance: Option<boolean>;
  paymentMethod: Option<PaymentMethod>;
};

export type EmployedProps = {
  fieldProps: <K extends keyof EmployedCardFormData>(
    name: K
  ) => ComputedFieldProps<EmployedCardFormData[K]>;
  disabled?: boolean;
};

export function useFormatEmploymentType() {
  const formatMessage = useFormatMessage();

  return (employmentType: api.EmploymentType) => {
    switch (employmentType) {
      case "Agreement":
        return formatMessage("StandardLoan.EmploymentType.agreement");
      case "IndefiniteContract":
        return formatMessage("StandardLoan.EmploymentType.indefiniteContract");
      case "LimitedContract":
        return formatMessage("StandardLoan.EmploymentType.limitedContract");
      case "Other":
        return formatMessage("StandardLoan.EmploymentType.other");
      case "SeasonalContract":
        return formatMessage("StandardLoan.EmploymentType.seasonalContract");
    }
  };
}

export type MaternityParentalLeaveFormData = {
  allowanceType: Option<api.AllowanceType>;
  monthlySocialBenefit: Option<number>;
};

export type MaternityParentalLeaveProps = {
  fieldProps: ComputedFieldProps<Option<api.AllowanceType>>;
};

export type AlimonyFormData = {
  alimonyChildrenInvolved: Option<number>;
  alimonyIdentifiable: Option<boolean>;
  alimonyOutsideCountry: Option<boolean>;
  monthlyAlimonyIncome: Option<number>;
  alimonyCurrency: Option<string>;
  paymentMethod: Option<PaymentMethod>;
};

export function useFormatAllowanceType() {
  const formatMessage = useFormatMessage();

  return (allowanceType: api.AllowanceType) => {
    switch (allowanceType) {
      case "Parental":
        return formatMessage("StandardLoan.AllowanceType.parentalAllowance");
      case "FutureParental":
        return formatMessage(
          "StandardLoan.AllowanceType.futureParentalAllowance"
        );
    }
  };
}

export function isOptionTrue(value: Option<boolean>): boolean {
  return pipe(value, option.getOrElse(constFalse));
}

export function useFormatPaymentMethod() {
  const formatMessage = useFormatMessage();

  return (paymentMethod: PaymentMethod) => {
    switch (paymentMethod) {
      case "Cash":
        return formatMessage("StandardLoan.PaymentMethod.Cash");
      case "BankTransfer":
        return formatMessage("StandardLoan.PaymentMethod.BankTransfer");
      case "SalarySentUCB":
        return formatMessage("StandardLoan.PaymentMethod.SalarySentUCB");
      case "SalarySentOtherBank":
        return formatMessage("StandardLoan.PaymentMethod.SalarySentOtherBank");
      case "PaymentInCash":
        return formatMessage("StandardLoan.PaymentMethod.PaymentInCash");
    }
  };
}

export function useFormatIncomeSourceType() {
  const formatMessage = useFormatMessage();

  return (incomeSource: FlattenedIncomeSourceType | SpecialType) => {
    switch (incomeSource) {
      case "Employed":
        return formatMessage("StandardLoan.IncomeSource.Employed");
      case "EmployedAbroad":
        return formatMessage("StandardLoan.IncomeSource.EmployedAbroad");
      case "Freelancer":
        return formatMessage("StandardLoan.IncomeSource.Freelancer");
      case "Pensioner":
        return formatMessage("StandardLoan.IncomeSource.Pensioner");
      case "TradesmanCoOperatingPerson":
        return formatMessage(
          "StandardLoan.IncomeSource.TradesManCoOperatingPerson"
        );
      case "MaternityLeave":
        return formatMessage("StandardLoan.IncomeSource.MaternityLeave");
      case "CompanyOwner":
        return formatMessage("StandardLoan.IncomeSource.CompanyOwner");
      case "Alimony":
        return formatMessage("StandardLoan.IncomeSource.Alimony");
      case "SpecialType":
        return formatMessage("StandardLoan.IncomeSource.SpecialType");
    }
  };
}

export type IncomeFormOutput = [JSX.Element, TaskEither<unknown, IncomeOutput>];
export type IncomeFormCardOutput = [
  JSX.Element,
  TaskEither<unknown, IncomeData>
];
