import * as t from "io-ts";
import { Option } from "fp-ts/Option";
import { PaymentMethod } from "../../domain";

export const PensionTypeCZ = t.keyof({
  Disability: true,
  OldAge: true,
  Orphans: true,
  Widower: true,
  Retirement: true,
});

export type PensionTypeCZ = t.TypeOf<typeof PensionTypeCZ>;

export const PensionTypeSK = t.keyof({
  OldAge: true,
  Widower: true,
  FullInvalidityPension: true,
  RetirementAnnuity: true,
  OtherPensionOrAllowance: true,
  Bursary: true,
});

export type PensionTypeSK = t.TypeOf<typeof PensionTypeSK>;

export const PensionType = t.union(
  [PensionTypeCZ, PensionTypeSK],
  "PensionType"
);

export type PensionType = t.TypeOf<typeof PensionType>;

export const RetirementRentType = t.keyof({
  Policeman: true,
  FireRescueCorps: true,
  CustomAdministration: true,
  PrisonService: true,
  GeneralInspectorate: true,
  SecurityInformationService: true,
  OfficeForForeignRelations: true,
  FormerArmyMembers: true,
  Other: true,
});

export type RetirementRentType = t.TypeOf<typeof RetirementRentType>;

export type PensionerFormFields = {
  pensionType: Option<PensionType>;
  monthlyPension: Option<number>;
  paymentMethod: Option<PaymentMethod>;
  monthlyRent: Option<number>;
  monthlyIncome: Option<number>;
};
